<template>
  <div>
    <a-row type="flex" class="max-width" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <!-- <a-breadcrumb-item>
          <router-link :to="'/products'">产品列表</router-link>
        </a-breadcrumb-item>-->
        <a-breadcrumb-item>
          <a @click="to_c1" class="router">{{data.category1}}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="to_c2" class="router">{{data.category2}}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="to_c3" class="router">{{data.category3}}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ data.name }}</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <a-spin class="max-width" :spinning="spinning" size="large" tip="加载中" style="min-height: 600px">
      <a-row class="max-width" type="flex" justify="space-between">
        <!-- 图片轮播图 -->
        <a-carousel
          arrows
          autoplay
          dots-class="slick-dots slick-thumb"
          class="custom-slick-arrow carousel"
        >
          <div slot="prevArrow" class="custom-slick-arrow" style="left: 20px; zIndex: 1">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 20px">
            <a-icon type="right-circle" />
          </div>
          <a
            slot="customPaging"
            slot-scope="props"
            v-if="data.small_image && data.small_image.length !== 0"
          >
            <img :src="data.small_image[props.i].url" />
          </a>
          <!-- 暂无图片占位 -->
          <div v-if="data.small_image && data.small_image.length === 0">
            <div class="small-img">
              <img mode="aspectFit" src="index/noimg.png" />
            </div>
          </div>
          <!-- 产品轮播图 -->
          <div v-for="image in data.small_image" :key="image.url">
            <div class="small-img">
              <img mode="aspectFit" :src="image.url" />
            </div>
          </div>
        </a-carousel>
        <!-- 文字 -->
        <a-skeleton :loading="spinning" :paragraph="{ rows: 11 }" style="width: 500px">
          <!-- 标题 -->
          <h1>{{ data.name }}</h1>
          <h4 class="theme" style="margin: 0 0 40px">
            适用酒店：
            <span class="hotel" v-for="hotel in data.hotel" :key="hotel.name">{{ hotel.name }}</span>
          </h4>
          <a-divider>价格</a-divider>
          <!-- 单价和数量 -->
          <div class="priceInfo">
            <span class="price">
              <span style="font-size:23px">￥</span>
              {{ data.price_settle }}
              <span
                style="color:#000;font-size:18px"
              >/{{ data.product_unit }}</span>
            </span>
            <span class="num">
              <h5>起订数量：{{ data.num_minimum }} (按倍数增减)</h5>
              <h5>装箱数量：{{ data.num_boxing }}</h5>
              <h5>累计销量：{{ data.sales }}</h5>
            </span>
          </div>
          <a-divider>订购</a-divider>
          <!-- 订购栏 -->
          <div class="priceInfo">
            <a-input-number
              :style="`width: ${data.customizable ? 150 : 200}px;`"
              size="large"
              v-model="amount"
              :min="data.num_minimum"
              :step="data.num_minimum"
              :formatter="value => `数量：${value}`"
            />
            <a-button
              size="large"
              type="primary"
              :style="`width: ${data.customizable ? 150 : 200}px;`"
              @click="buy"
              :loading="isButtonLoading"
            >
              <a-icon type="shopping-cart" />订购
            </a-button>
            <a-button
              v-if="data.customizable"
              size="large"
              style="width:150px"
              @click="showCustom = true"
            >
              <a-icon type="scissor" />定制
            </a-button>
          </div>
        </a-skeleton>
      </a-row>
      <!-- 供应商信息 -->
      <div style="width:100%;background-color:#f7f7f7;font-size:20px;padding-left:10px">供应商信息</div>
      <a-descriptions :column="1" style="padding-left:10px;margin-top:10px">
        <a-descriptions-item>{{ data.company_name}}</a-descriptions-item>
        <a-descriptions-item>{{ data.contact}}-{{ data.phone}}</a-descriptions-item>
      </a-descriptions>
      <!-- 描述 -->
      <div style="width:100%;background-color:#f7f7f7;font-size:20px;padding-left:10px">产品详情</div>
      <a-descriptions :column="4" style="padding-left:10px;margin-top:10px">
        <a-descriptions-item
          :key="i.title"
          :label="i.title"
          v-for="i in [
          { key: 'category2', title: '产品大类' },
          { key: 'category3', title: '产品小类' },
          { key: 'brand_product', title: '产品品牌' },
          { key: 'product_nature', title: '产品性质' },
          { key: 'specification', title: '产品规格' },
          { key: 'supply_cycle', title: '供货周期' },
          { key: 'sn_product_info', title: '产品编码' }
        ]"
        >{{ data[i.key] }}</a-descriptions-item>
        <a-descriptions-item
          label="支持定制"
        >{{ data.customizable === 1 ? '支持' : data.customizable === 0 ? '不支持':'' }}</a-descriptions-item>
        <!-- </a-descriptions>
        <a-descriptions bordered :column="2" size="samll">-->
        <a-descriptions-item
          :span="4"
          :key="i.title"
          :label="i.title"
          v-for="i in [
          { key: 'guide_format', title: '规格说明' },
          { key: 'guide_order', title: '订货指引' },
          { key: 'freight_note', title: '运费描述' },
          { key: 'guide_acceptance', title: '验收指引' },
          { key: 'guide_quality', title: '质量描述' },
        ]"
        >{{ data[i.key] }}</a-descriptions-item>
        <a-descriptions-item
          :span="4"
          label="详情"
          v-if="data.big_image && data.big_image.length > 0"
        >
          <p style="text-align: center; margin: 0" v-for="i in data.big_image" :key="i.url">
            <img style="width: 100%;" v-lazy="i.url" alt="图片加载失败" />
          </p>
        </a-descriptions-item>
      </a-descriptions>
      <div style="padding: 20px"></div>
      <!-- 定制需求 -->
      <a-modal
        v-model="showCustom"
        title="定制需求"
        okText="提交"
        @ok="custom"
        :confirmLoading="isModalLoading"
      >
        <a-alert description="向产品供应商发送您的定制需求" type="warning" show-icon />
        <a-form layout="vertical" style="margin: 20px 0">
          <a-form-item label="计划订购数量">
            <a-input-number style="width: 100%" :setp="1" :min="0" v-model="modalData.amount" />
          </a-form-item>
          <a-form-item label="定制单价">
            <a-input-number
              style="width: 100%"
              :setp="1"
              :min="0"
              v-model="modalData.price_custom"
            />
          </a-form-item>
          <a-form-item label="产品定制需求">
            <a-input
              :maxLength="250"
              type="textarea"
              style="height:150px"
              v-model="modalData.note"
              placeholder="产品供应商将根据您的定制需求，开出合理的产品价格"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {
      spinning: true,
      data: {},
      amount: 0, // 数量
      isButtonLoading: false, // 是否显示按钮加载
      isModalLoading: false, // 是否显示弹框加载
      modalData: {},
      showCustom: false // 是否显示定制弹框
    }
  },

  mounted() {
    this.get('getProduct', {
      id_product_info: this.$route.query.id,
      sn_product_info: this.$route.query.sn,
      status: 1,
      is_show: 1
    }).then((res) => {
      this.data = res
      this.spinning = false
      this.amount = res.num_minimum
    }).catch((err) => {
      this.$message.error(err.msg)
    })
  },

  methods: {
    buy() {
      this.isButtonLoading = true
      this.post('addCartForFranchisee', {
        id_product_info: this.data.id_product_info,
        amount: this.amount
      })
        .then(() => {
          this.$message.success(`${this.data.name}*${this.amount} 已加入购物车`)
          this.isButtonLoading = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isButtonLoading = false
        })
    },
    custom() {
      if (parseFloat(this.modalData.amount) !== ~~this.modalData.amount) {
        return this.$message.warn('请填写有效的订购数量')
      }
      if (!this.modalData.note) return this.$message.warn('请填写定制需求')
      this.isModalLoading = true
      this.modalData.id_product_info = this.data.id_product_info
      this.post('addCustomCartForFranchisee', this.modalData)
        .then(() => {
          this.isModalLoading = false
          this.showCustom = false
          this.$confirm({
            icon: 'clock-circle',
            title: '已定制',
            content: '商品已加入定制购物车',
            okText: '前往查看',
            cancelText: '留在此页面',
            onOk: () => {
              this.$router.push({ name: 'customcart', params: { tab: 15 } })
            }
          })
        })
        .catch(() => {
          this.$message.error('表单数据不完整或有误')
          this.isModalLoading = false
          this.showCustom = false
        })
    },
    // 回一级路由
    to_c1() {
      this.$router.replace({ name: 'home', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: this.data.id_category1
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    },
    // 回二级路由
    to_c2() {
      this.$router.replace({ name: 'home', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: this.data.id_category2
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    },
    to_c3() {
      this.$router.replace({ name: 'home', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: this.data.id_category3
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    }
  }
}
</script>

<style scoped>
.max-width {
  margin: 20px auto;
  padding: 0 50px;
  max-width: 1600px;
  min-width: 900px;
}
/* 文字 */
.hotel::after {
  content: "/";
}
.hotel:last-of-type::after {
  content: " ";
}
.priceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.price {
  padding: 20px 0;
  color: #d00;
  width: 250px;
  border-right: 1px #ddd solid;
  font-size: 40px;
  font-weight: 450;
  justify-content: center;
}
.num {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.mainInfo .note {
  color: #888;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
/* 大图片 */
.big-images {
  display: flex;
  flex-direction: column;
}
/* 轮播图 */
.carousel {
  border-radius: 5px;
  overflow: hidden;
  width: 500px;
  height: 500px;
}
.small-img {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-img img {
  border-radius: 5px;
  max-width: 100%;
  max-height: 500px;
}
.carousel img {
  width: auto;
  height: auto;
}
/* 轮播图导入 */
.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  border-radius: 50%;
  background-color: rgb(20, 18, 18);
  opacity: 0.2;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  border-radius: 10px;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
/* 文字显示样式 */
.router:hover {
  color: #347f9b;
  text-decoration: underline;
}
</style>
